import React, { lazy, Suspense } from "react";
import { Route, Router, browserHistory } from "react-router";
import LoadingLabel from "../components/common/loaders/loadingLabel";

/* Main Layout */
const Main = lazy(() => import("../components/common/layout/Main"));

/* Protected Route */
const ProtectedRoute = lazy(() => import("../components/common/layout/protectedRoute"));

/* Authentication module */
const Login = lazy(() => import("../components/authentication/loginForm"));
const ResetPassword = lazy(() => import("../components/authentication/resetPassword"));
const AccountRecovery = lazy(() => import("../components/authentication/verifyToken"));
const Logout = lazy(() => import("../components/authentication/logout"));
const Profile = lazy(() => import("../components/profile/main"));

/* Dashboard */
const Dashboard = lazy(() => import("../components/dashboard/main"));
const Notifications = lazy(() => import("../components/dashboard/notificationList"));

/* Tasks */
const Calendar = lazy(() => import("../components/tasks/main"));

/* Administrator */
const AdministratorMenu = lazy(() => import("../components/admin/administratorMenu"));
const Countries = lazy(() => import("../components/admin/countries/main"));
const CreateCountry = lazy(() => import("../components/admin/countries/createCountry"));
const UserTypes = lazy(() => import("../components/admin/userTypes/main"));
const CreateUserType = lazy(() => import("../components/admin/userTypes/createUserTypes"));
const UserHeirarchy = lazy(() => import("../components/admin/userHeirarchy/main"));
const MailTemplates = lazy(() => import("../components/admin/mailTemplates/main"));
const CreateMailTemplate = lazy(() => import("../components/admin/mailTemplates/createTemplate"));
const MailSettings = lazy(() => import("../components/admin/mailSettings/main"));
const CreateMailSettings = lazy(() => import("../components/admin/mailSettings/createMailSetting"));
const DocumentTemplates = lazy(() => import("../components/admin/templates/main"));
const CreateTemplate = lazy(() => import("../components/admin/templates/create"));
const WidgetCreate = lazy(() => import("../components/common/widgets/createWidget"));

/* Transaction Settings */
const FeeSettings = lazy(() => import("../components/admin/fee/main"));
const CreateFee = lazy(() => import("../components/admin/fee/create"));

/* Manage Prospects */
const Prospects = lazy(() => import("../components/prospects/main"));
const ProspectInvitaion = lazy(() => import("../components/prospects/prospectInvitaion"));
const ProspectLayout = lazy(() => import("../components/prospects/layout"));
const ProspectSnapshot = lazy(() => import("../components/prospects/prospectSnapshot"));
const CreateProspect = lazy(() => import("../components/prospects/createProspect"));
const BulkUpload = lazy(() => import("../components/prospects/bulkUpload"));

/* Manage Entities */
const EntityLayout = lazy(() => import("../components/entities/layout"));
const Entities = lazy(() => import("../components/entities/main"));
const CreateEntity = lazy(() => import("../components/entities/createEntityNew"));
const EntitySnapshot = lazy(() => import("../components/entities/entitySnapshot"));

/* Manage Business Partners */
const BusinessPartnerLayout = lazy(() => import("../components/businessPartners/layout"));
const BusinessPartners = lazy(() => import("../components/businessPartners/main"));
const CreateBusinessPartner = lazy(() => import("../components/businessPartners/createEntity"));
const BusinessPartnerSnapshot = lazy(() => import("../components/businessPartners/entitySnapshot"));
const InvitationMain = lazy(() => import("../components/businessPartners/invitationMain"));
const EntityMain = lazy(() => import("../components/businessPartners/entityMain"));

/* Country Office */
const CountryOffice = lazy(() => import("../components/countryOffice/main"));

/* Client Portal */
const CreateClientTransaction = lazy(() => import("../components/client/create_transaction"));
const ClientInvoices = lazy(() => import("../components/client/main"));

/* Investment Partner Portal */
const InvestmentPartnerTransactions = lazy(() => import("../components/investmentPartner/invoiceTransactions"));
const InvestmentPartnerLoans = lazy(() => import("../components/investmentPartner/loanTransactions"));

/* Mail Module (Old) */
const MailLayout = lazy(() => import("../components/mail/layout"));
const MailBox = lazy(() => import("../components/mail/mailbox"));
const ReadMail = lazy(() => import("../components/mail/readMail"));
const ComposeMail = lazy(() => import("../components/mail/compose"));

/* Gmail Module */
const GmailLayout = lazy(() => import("../components/gmail/layout"));
const GmailMessagesList = lazy(() => import("../components/gmail/messagesList"));
const GmailReadMail = lazy(() => import("../components/gmail/readMail"));
const GmailComposeMail = lazy(() => import("../components/gmail/compose-mail"));

/* Documents Module */
const Documents = lazy(() => import("../components/documents/main"));

/* Assessments Module */
const Assessments = lazy(() => import("../components/assessments/main"));
const Sections = lazy(() => import("../components/assessments/sections"));
const AssessmentAnswers = lazy(() => import("../components/assessments/assessmentAnswers"));

/* Loans */
const Loans = lazy(() => import("../components/loans/main"));
const LoanDetail = lazy(() => import("../components/loans/detail"));
const NewLoan = lazy(() => import("../components/loans/newLoan"));
const LoanView = lazy(() => import("../components/loans/viewLoan"));
const EntityLoan = lazy(() => import("../components/loans/entityLoanList"));

/** Transactions */
const Transactions = lazy(() => import("../components/transactions/main"));
const TransactionSnapshot = lazy(() => import("../components/transactions/transaction_snapshot"));
const TransactionLog = lazy(() => import("../components/auditTrail/transactionLog"));
const TransactionSnapshotView = lazy(() => import("../components/transactions_view/transaction_snapshot"));
const InvoiceSnapshot = lazy(() => import("../components/invoice/main"));
const NotesSnapshot = lazy(() => import("../components/prospects/noteViews"));
const InvoiceMain = lazy(() => import("../components/invoice/main"));

/* Reports */
const TransactionsReport = lazy(() => import("../components/reports/transactionsReport"));
const TransReport = lazy(() => import("../components/reports/transReport1/main"));
const TransReport1 = lazy(() => import("../components/reports/transReport/main"));
const AgedReceivablesReport = lazy(() => import("../components/reports/agedReceivablesReport"));
const BusinessPartnerSalesReport = lazy(() => import("../components/reports/businessPartnerSalesReport"));
const BusinessPartnerBillingReport = lazy(() => import("../components/reports/bpBilling/main"));
const PerformanceReport = lazy(() => import("../components/reports/bpPerformance/main"));
const UsersReport = lazy(() => import("../components/reports/users/main"));
const ProspectsReport = lazy(() => import("../components/reports/prospects/main"));
const DueDaysReports = lazy(() => import("../components/reports/dueDaysReports"));
const InvestorAvailablityReport = lazy(() => import("../components/reports/investorAvailablityReport"));
const ContactReports = lazy(() => import("../components/reports/contactReport"));
const DebtorStatement = lazy(() => import("../components/reports/debtorStatement"));
const PointTimeReport = lazy(() => import("../components/reports/pointTimeReport/main"));
const IpBilling = lazy(() => import("../components/reports/ipTransactions/main"));
const EntityReports = lazy(() => import("../components/reports/entityReport"));
const TransactionReconciliationReport = lazy(() => import("../components/reports/transactionReconciliation1/main"));
const TransactionReconciliationReport1 = lazy(() => import("../components/reports/transactionReconciliation/main"));

/* Newsletters (Old) */
const NewsLetterTemplates = lazy(() => import("../components/newsletters/newsLetterMain"));
const NewsLetterCreate = lazy(() => import("../components/newsletters/newsLetterCreate"));

/* Manage Newsletters */
const NewsLetters = lazy(() => import("../components/news-letter/main"));
const CreateNewsLetters = lazy(() => import("../components/news-letter/create"));
const NLApproval = lazy(() => import("../components/news-letter/approval"));
const NLReview = lazy(() => import("../components/news-letter/review"));
const NLList = lazy(() => import("../components/news-letter/list"));
const NLPublish = lazy(() => import("../components/news-letter/publish"));
const NLBanners = lazy(() => import("../components/news-letter/banners"));
const NLHelp = lazy(() => import("../components/news-letter/help"));
const NLPublishQueue = lazy(() => import("../components/news-letter/createPublishQueue"));
const NLUnsubscribedList = lazy(() => import("../components/news-letter/unsubscribeList"));

/* Dashboard Widgets */
const WidgetMain = lazy(() => import("../components/common/widgets/main"));

const CreateAccountantClient = lazy(() => import("../components/accountant/create"));
const ListAccountantClients = lazy(() => import("../components/accountant/list"));
const AccountantClientsBulkUpload = lazy(() => import("../components/accountant/clientBulkUpload"));
const GoogleSettings = lazy(() => import("../components/accountant/googleSettings"));

/* Additional Fields */
const AdditionalFields = lazy(() => import("../components/admin/additionalFields/main"));

/* Wiki */
const WikiMenu = lazy(() => import("../components/wiki/wikiMenu"));
const CreateWiki = lazy(() => import("../components/wiki/wikiCreate"));
const ListWikiMenu = lazy(() => import("../components/wiki/listWikiMenu"))
const WikiTreeView = lazy(() => import("../components/wiki/wikiTreeView"))
const ListGCalendarEvents = lazy(() => import("../components/googleCalendar/main"));

export default (
  <Suspense fallback={<LoadingLabel></LoadingLabel>}>
    <Router history={browserHistory}>
      <Route path="/" component={Main}>
        {/** ===================== Authentication ========================= */}
        <Route path="login" component={Login}></Route>
        <Route path="reset-password" component={ResetPassword}></Route>
        <Route path="logout" component={Logout}></Route>
        <Route path="account-recovery" component={AccountRecovery}></Route>
        <ProtectedRoute path="/profile" component={Profile} />
        <ProtectedRoute path="/password" component={Profile} />
        {/** ===================== Dashboard ============================== */}
        <ProtectedRoute path="dashboard" component={Dashboard} />
        <ProtectedRoute path="calendar" component={Calendar} />
        <ProtectedRoute path="/documents" component={Documents} />
        <ProtectedRoute path="/google-calendar/events" component={ListGCalendarEvents} />

        <ProtectedRoute path="notifications" component={Notifications} />

        {/** ===================== Administrator Module ============================== */}
        <Route path="/administrator" component={AdministratorMenu}>
          <ProtectedRoute
            path="/administrator/countries"
            component={Countries}
          />
          <ProtectedRoute
            exact
            path="/business-partner/invitations"
            component={InvitationMain}
          />

          <ProtectedRoute
            path="/administrator/countries/create"
            component={CreateCountry}
          />
          <ProtectedRoute
            exact
            path="/administrator/countries/edit"
            component={CreateCountry}
          />
          <ProtectedRoute
            path="/administrator/user-types"
            component={UserTypes}
          />
          <ProtectedRoute
            exact
            path="/administrator/user-types/create"
            component={CreateUserType}
          />
          <ProtectedRoute
            exact
            path="/administrator/user-types/edit"
            component={CreateUserType}
          />
          <ProtectedRoute
            path="/administrator/user-heirarchy"
            component={UserHeirarchy}
          />
          <ProtectedRoute
            path="/administrator/mail-templates"
            component={MailTemplates}
          />
          <ProtectedRoute
            path="/administrator/mail-templates/create"
            component={CreateMailTemplate}
          />
          <ProtectedRoute
            path="/administrator/mail-templates/edit"
            component={CreateMailTemplate}
          />
          <ProtectedRoute
            path="/administrator/mail-settings"
            component={MailSettings}
          />
          <ProtectedRoute
            exact
            path="/administrator/mail-settings/create"
            component={CreateMailSettings}
          />
          <ProtectedRoute
            exact
            path="/administrator/mail-settings/edit"
            component={CreateMailSettings}
          />

          <ProtectedRoute
            exact
            path="/administrator/document-templates"
            component={DocumentTemplates}
          />

          <ProtectedRoute
            exact
            path="/administrator/document-templates/create"
            component={CreateTemplate}
          />

          <ProtectedRoute
            exact
            path="/administrator/document-templates/edit"
            component={CreateTemplate}
          />

          <ProtectedRoute
            exact
            path="/administrator/widgets"
            component={WidgetMain}
          />
          <ProtectedRoute
            exact
            path="/administrator/widgets/create"
            component={WidgetCreate}
          />

          <ProtectedRoute
            exact
            path="/administrator/widgets/edit"
            component={WidgetCreate}
          />

          <ProtectedRoute
            path="/administrator/transaction/fee"
            component={FeeSettings}
          />
          <ProtectedRoute
            path="/administrator/transaction/fee/create"
            component={CreateFee}
          />
          <ProtectedRoute
            path="/administrator/transaction/fee/edit"
            component={CreateFee}
          />

          {/** ==================NewsLetter messages ============== */}

          <ProtectedRoute
            path="/newsletter/manage-newsletter"
            component={NewsLetterTemplates}
          />

          <ProtectedRoute
            path="/newsletter/create-newsletter"
            component={NewsLetterCreate}
          />
          <ProtectedRoute
            path="/newsletter/edit-newsletter"
            component={NewsLetterCreate}
          />

          {/** ===================== Assessments Module ============================== */}
          <ProtectedRoute
            exact
            path="/administrator/assessments"
            component={Assessments}
          />
          <ProtectedRoute
            exact
            path="/administrator/assessments/sections"
            component={Sections}
          />
        </Route>

        {/** ===================== Prospect Management ============================== */}
        <Route path="/prospects/details/" component={ProspectLayout}>
          <ProtectedRoute
            exact
            path="/prospects/details/snapshot"
            component={ProspectSnapshot}
          />
          <ProtectedRoute
            exact
            path="/prospects/details/profile"
            component={CreateProspect}
          />
          {/** ===================== Task Calendar ================================== */}
          <ProtectedRoute
            path="/prospects/details/calendar"
            component={Calendar}
          />
          {/** ===================== Mail Module ============================== */}
          <Route path="/prospects/details/mail" component={MailLayout}>
            <ProtectedRoute
              exact
              path="/prospects/details/mail/inbox"
              component={MailBox}
            />
            <ProtectedRoute
              exact
              path="/prospects/details/mail/read"
              component={ReadMail}
            />
            <ProtectedRoute
              exact
              path="/prospects/details/mail/compose"
              component={ComposeMail}
            />
          </Route>

          {/** ===================== GMail Module ============================== */}
          <Route path="/prospects/details/gmail" component={GmailLayout}>
            <ProtectedRoute path="/prospects/details/gmail/messages" component={GmailMessagesList} />
            <ProtectedRoute path="/prospects/details/gmail/read-mail" component={GmailReadMail} />
            <ProtectedRoute path="/prospects/details/gmail/compose-mail" component={GmailComposeMail} />
          </Route>

          {/** ===================== Documents Module ============================== */}
          <ProtectedRoute
            path="/prospects/details/documents"
            component={Documents}
          />
        </Route>

        {/** ===================== Introduction Management ============================== */}
        <Route path="/introductions/details/" component={ProspectLayout}>
          <ProtectedRoute
            exact
            path="/introductions/details/snapshot"
            component={ProspectSnapshot}
          />
          <ProtectedRoute
            exact
            path="/introductions/details/profile"
            component={CreateProspect}
          />
        </Route>

        <ProtectedRoute exact path="/prospects" component={Prospects} />
        <ProtectedRoute exact path="/prospects/invitations" component={ProspectInvitaion} />
        <ProtectedRoute exact path="/prospects/create" component={CreateProspect} />
        <ProtectedRoute exact path="/prospects/bulk-upload" component={BulkUpload} />

        <ProtectedRoute exact path="/introductions" component={Prospects} />
        <ProtectedRoute exact path="/introductions/invitations" component={ProspectInvitaion} />
        <ProtectedRoute exact path="/introductions/create" component={CreateProspect} />
        <ProtectedRoute exact path="/introductions/bulk-upload" component={BulkUpload} />

        {/** ===================== Entity Management ============================== */}
        <Route path="/entities/details/" component={EntityLayout}>
          <ProtectedRoute
            exact
            path="/entities/details/snapshot"
            component={EntitySnapshot}
          />
          <ProtectedRoute
            exact
            path="/entities/details/profile"
            component={CreateEntity}
          />

          {/** ===================== Mail Module ============================== */}
          <Route path="/entities/details/mail" component={MailLayout}>
            <ProtectedRoute
              exact
              path="/entities/details/mail/inbox"
              component={MailBox}
            />
            <ProtectedRoute
              exact
              path="/entities/details/mail/read"
              component={ReadMail}
            />
            <ProtectedRoute
              exact
              path="/entities/details/mail/compose"
              component={ComposeMail}
            />
          </Route>

          {/** ===================== GMail Module ============================== */}
          <Route path="/entities/details/gmail" component={GmailLayout}>
            <ProtectedRoute path="/entities/details/gmail/messages" component={GmailMessagesList} />
            <ProtectedRoute path="/entities/details/gmail/read-mail" component={GmailReadMail} />
            <ProtectedRoute path="/entities/details/gmail/compose-mail" component={GmailComposeMail} />
          </Route>

          {/** ===================== Documents Module ============================== */}
          <ProtectedRoute
            path="/entities/details/documents"
            component={Documents}
          />
          {/** ===================== Assessment Answers ============================= */}
          <ProtectedRoute
            path="/entities/details/assessment"
            component={AssessmentAnswers}
          />
          {/** ===================== Task Calendar ================================== */}
          <ProtectedRoute
            path="/entities/details/calendar"
            component={Calendar}
          />

          {/** ===================== Transactions Module ================================== */}
          <ProtectedRoute
            path="/entities/details/transactions"
            component={Transactions}
            exact
          />

          <ProtectedRoute
            path="/entities/details/invoices"
            component={InvoiceSnapshot}
            exact
          />
          <ProtectedRoute
            path="/entities/details/notes"
            component={NotesSnapshot}
            exact
          />
          <ProtectedRoute
            path="/entities/details/loans"
            component={EntityLoan}
          />

          <ProtectedRoute path="/loans/newLoan" component={NewLoan} />
        </Route>
        <ProtectedRoute path="/loans/viewLoan" component={LoanView} />
        {/** ===================== List all transactions ================================== */}
        <ProtectedRoute path="/transactions" component={Transactions} exact />
        <ProtectedRoute
          path="/entities/details/transactions/snapshot"
          component={TransactionSnapshot}
          exact
        />
        <ProtectedRoute
          path="/entities/details/transactions/audit-report"
          component={TransactionLog}
          exact
        />
        <Route
          path="/entities/details/transactions/view/snapshot"
          component={TransactionSnapshotView}
          exact
        />
        <ProtectedRoute exact path="/entities" component={Entities} />
        <ProtectedRoute
          exact
          path="/entities/create"
          component={CreateEntity}
        />

        {/** =====================Country Office Module ============================== */}
        <ProtectedRoute
          exact
          path="/country-office"
          component={CountryOffice}
        />
        {/** ===================== Business Partners Module ============================== */}
        <Route
          path="/business-partner/details/"
          component={BusinessPartnerLayout}
        >
          <ProtectedRoute
            exact
            path="/business-partner/details/snapshot"
            component={BusinessPartnerSnapshot}
          />

          <ProtectedRoute
            exact
            path="/business-partner/details/profile"
            component={CreateBusinessPartner}
          />

          <ProtectedRoute
            exact
            path="/business-partner/entities-listing"
            component={EntityMain}
          />

          <ProtectedRoute
            path="/business-partner/details/bp-transactions"
            component={Transactions}
            exact
          />

          <ProtectedRoute
            path="/business-partner/details/loans"
            component={EntityLoan}
            exact
          />

          {/** ===================== Mail Module ============================== */}
          <Route path="/business-partner/details/mail" component={MailLayout}>
            <ProtectedRoute
              exact
              path="/business-partner/details/mail/inbox"
              component={MailBox}
            />
            <ProtectedRoute
              exact
              path="/business-partner/details/mail/read"
              component={ReadMail}
            />
            <ProtectedRoute
              exact
              path="/business-partner/details/mail/compose"
              component={ComposeMail}
            />
          </Route>

          {/** ===================== GMail Module ============================== */}
          <Route path="/business-partner/details/gmail" component={GmailLayout}>
            <ProtectedRoute path="/business-partner/details/gmail/messages" component={GmailMessagesList} />
            <ProtectedRoute path="/business-partner/details/gmail/read-mail" component={GmailReadMail} />
            <ProtectedRoute path="/business-partner/details/gmail/compose-mail" component={GmailComposeMail} />
          </Route>

          {/** ===================== Documents Module ============================== */}
          <ProtectedRoute
            path="/business-partner/details/documents"
            component={Documents}
          />
        </Route>
        <ProtectedRoute
          exact
          path="/business-partner"
          component={BusinessPartners}
        />
        <ProtectedRoute
          exact
          path="/business-partner/create"
          component={CreateBusinessPartner}
        />
        <ProtectedRoute
          exact
          path="/business-partner/edit"
          component={CreateBusinessPartner}
        />
        {/** ===================== Loans ============================== */}
        <ProtectedRoute path="/loans" component={Loans} />
        <ProtectedRoute path="/loans/details" component={LoanDetail} />

        {/** ===================== Reports ============================ */}
        <ProtectedRoute
          path="/reports/transactions-report"
          component={TransactionsReport}
        />
        <ProtectedRoute
          path="/reports/trans-report"
          component={TransReport}
        />
        <ProtectedRoute
          path="/reports/trans-report1"
          component={TransReport1}
        />
        <ProtectedRoute
          path="/reports/aged-receivables-report"
          component={AgedReceivablesReport}
        />
        <ProtectedRoute
          path="/reports/business-partner-sales-report"
          component={BusinessPartnerSalesReport}
        />
        <ProtectedRoute
          path="/reports/business-partner-billing-report"
          component={BusinessPartnerBillingReport}
        />
        <ProtectedRoute
          path="/reports/business-partner-performance"
          component={PerformanceReport}
        />
        <ProtectedRoute
          path="/reports/users-report"
          component={UsersReport}
        />
        <ProtectedRoute
          path="/reports/prospects-report"
          component={ProspectsReport}
        />
        <ProtectedRoute
          path="/reports/due-days-report"
          component={DueDaysReports}
        />
        <ProtectedRoute
          path="/reports/investor-availability-report"
          component={InvestorAvailablityReport}
        />
        <ProtectedRoute
          path="/reports/contact-report"
          component={ContactReports}
        />
        <ProtectedRoute
          path="/reports/debtor-statement-report"
          component={DebtorStatement}
        />
        <ProtectedRoute
          path="/reports/point-time-report"
          component={PointTimeReport}
        />
        <ProtectedRoute
          path="/reports/entity-report"
          component={EntityReports}
        />
        <ProtectedRoute
          path="/reports/ip-billing-report"
          component={IpBilling}
        />
        <ProtectedRoute
          path="/reports/transaction-reconciliation"
          component={TransactionReconciliationReport}
        />
        <ProtectedRoute
          path="/reports/transaction-reconciliation1"
          component={TransactionReconciliationReport1}
        />

        {/***************** INVOICE LIST**************** */}
        <ProtectedRoute path="/invoices" component={InvoiceMain} exact />
        {/***************** Loan List **************** */}
        <ProtectedRoute path="/loans-list" component={EntityLoan} exact />
        {/***************** CLIENT PORTAL **************** */}
        <ProtectedRoute
          path="/client/transactions"
          component={CreateClientTransaction}
          exact
        />
        <ProtectedRoute
          path="/client/invoices"
          component={ClientInvoices}
          exact
        />
        {/*----------------News Letter---------------------*/}
        <Route path="/news-letters" component={NewsLetters}>
          <ProtectedRoute path="/news-letters/create" component={CreateNewsLetters} />
          <ProtectedRoute path="/news-letters/edit" component={CreateNewsLetters} />
          <ProtectedRoute path="/news-letters/create/publish-queue" component={NLPublishQueue} />
          <ProtectedRoute path="/news-letters/approval" component={NLApproval} />
          <ProtectedRoute path="/news-letters/review" component={NLReview} />
          <ProtectedRoute path="/news-letters/list" component={NLList} />
          <ProtectedRoute path="/news-letters/publish" component={NLPublish} />
          <ProtectedRoute path="/news-letters/banners" component={NLBanners} />
          <ProtectedRoute path="/news-letters/help" component={NLHelp} />
          <ProtectedRoute path="/news-letters/unsubscribed-users" component={NLUnsubscribedList} />
        </Route>
        {/*---------------Additional Fields-----------------*/}
        <ProtectedRoute path="administrator/additional-fields" component={AdditionalFields} />
        {/*----------------Accountant---------------------*/}
        <ProtectedRoute path="/myclients" component={ListAccountantClients} />
        <ProtectedRoute path="/myclients/create" component={CreateAccountantClient} />
        <ProtectedRoute path="/myclients/bulk-upload" component={AccountantClientsBulkUpload} />
        <ProtectedRoute path="/google-settings" component={GoogleSettings} />


        {/***************** Investment Partner Portal **************** */}
        <ProtectedRoute
          path="/investment-partner/transactions"
          component={InvestmentPartnerTransactions}
          exact
        />

        <ProtectedRoute
          path="/investment-partner/loans"
          component={InvestmentPartnerLoans}
          exact
        />

        {/**========================================Wiki Component==========================* */}
        <Route path="/wikimenu" component={WikiMenu}>
          <ProtectedRoute
            path="/wikimenu/create"
            component={CreateWiki}
          />
          <ProtectedRoute
            path="/wikimenu/menus"
            component={ListWikiMenu}
          />
          <ProtectedRoute
            exact
            path="/wikimenu/edit"
            component={CreateWiki}
          />

        </Route>
        <Route path="/wiki" component={WikiTreeView}></Route>
      </Route>
      {/****************************** */}
    </Router>
  </Suspense>
);
