import { POPULATE_LOAN } from "../actions/loan-actions";
import { GENERATE_SCH } from "../actions/loan-actions";
import { ADD_FIX_FEES } from "../actions/loan-actions";
import { ADD_CHARGES } from "../actions/loan-actions";
import { ADD_DISBURSEMENT } from "../actions/loan-actions";
import { ADD_CUSTOM_LOAN } from "../actions/loan-actions";
import { EDIT_CUSTOM_LOAN } from "../actions/loan-actions";
const initialState = {
  loanDetails: [],
  loanSchedule: [] /*------Principal & Interest schedule */,
  loanScheduleI: [] /*------Interest only schedule */,
  loanScheduleC: [] /*------Custom schedule */,
  loanDisbursement: [],
  loanFees: [],
  loanParticipation: [],
  loanCharges: [],
  loanPayments: [],
};
export default function loanReducer(state = [], { type, payload }) {
  switch (type) {
    case POPULATE_LOAN:
      return payload.details;
    case GENERATE_SCH:
      return payload.details;
    case ADD_FIX_FEES:
      return payload.details;
    case ADD_CHARGES:
      return payload.details;
    case ADD_DISBURSEMENT:
      return payload.details;
    case ADD_CUSTOM_LOAN:
      const temp = [
        ...payload.loan_store["loanScheduleC"],
        payload.custom_schedule,
      ];
      payload.loan_store["loanScheduleC"] = temp;

      return payload.loan_store;
    case EDIT_CUSTOM_LOAN:
      return payload.loan_store;
    default:
      return initialState;
  }
}
