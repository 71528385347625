import {
  POPULATE_INVOICE,
  ADD_FEE,
  ADD_INVOICE,
  UPDATE_INVOICE,
  ADD_DISBURSEMENT,
  ADD_PARTICIPANTS,
  ADD_PAYMENTS,
  RESET_TRANSACTION
} from "../actions/transaction-actions";

const initialState = {
  transactionDetails: [],
  invoices: [],
  defaults: [],
  feeDetails: {
    feeList: [],
    selectedFee: { label: "Select fee structure", value: "" },
    latePaymentFee: "",
    insuranceFee: "",
    charges: []
  },
  disbursement: [],
  participants: [],
  payments: [],
  templates: [],
  parties: [],
  receiptsSummary: []
};

export default function transactionsReducer(state = [], { type, payload }) {
  switch (type) {
    case POPULATE_INVOICE:
      return payload.invoice;
    case ADD_INVOICE:
      const invoices = [
        ...payload.transStoreData["invoices"],
        payload.transInvoiceData
      ];
      payload.transStoreData["invoices"] = invoices;
      return payload.transStoreData;
    case UPDATE_INVOICE:
      return payload.transStoreData;
    case ADD_FEE:
      return payload.fee;
    case ADD_DISBURSEMENT:
      return payload.transStoreData;
    case ADD_PARTICIPANTS:
      return payload.participants;
    case ADD_PAYMENTS:
      return payload.payments;
    case RESET_TRANSACTION:
      return initialState;
    default:
      return initialState;
  }
}
